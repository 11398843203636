import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { FaBars, FaTimes } from 'react-icons/fa'; 
import './Navbar.css';

const Navbar = () => {
  const [menuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  return (
    <nav className="navbar">
      <div className="menu-icon" onClick={toggleMenu}>
        {menuOpen ? <FaTimes size={24} color="#fff" /> : <FaBars size={24} color="#fff" />}
      </div>
      <div className={`nav-links ${menuOpen ? 'open' : ''}`}>
        <Link to="/" onClick={toggleMenu}>Home</Link>
        <Link to="/about" onClick={toggleMenu}>About</Link>
        <Link to="/airdroppage" onClick={toggleMenu}>EXCR Airdrop</Link>
        <Link to="/abouttoken" onClick={toggleMenu}>About EXCR</Link>
        <Link to="/dashboard" onClick={toggleMenu}>Airdrop Dashboard</Link>
        <Link to="/walletloginpage" onClick={toggleMenu}>Wallet Login Page</Link>
        <Link to="/contact" onClick={toggleMenu}>Contact</Link>
        {/* Add other navigation items as needed */}
        {/* ... other links */}
      </div>
    </nav>
  );
};

export default Navbar;
