import React from 'react';
import logoImage from '../img/logo.png'; // Path to your logo image
import './Loader.css'; // Path to your Loader CSS

const Loader = () => {
  return (
    <div className="loader">
      <img src={logoImage} alt="Loading..." className="loader-logo" />
    </div>
  );
};

export default Loader;
