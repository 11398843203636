import React, { useState, useEffect, useCallback } from 'react';
import { FaQrcode, FaCopy, FaPaperPlane, FaDownload, FaUnlock } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import QRCode from 'qrcode.react';
import './Wallet.css';
import Footer from './Footer';
import logoImage from '../img/Logo-horizontal.png';
import SendModal from './SendModal';

const apiUrl = process.env.REACT_APP_API_URL;

const Wallet = () => {
    const [isDarkMode, setIsDarkMode] = useState(true);
    const [balance, setBalance] = useState(0);
    const [showBalance, setShowBalance] = useState(true);
    const navigate = useNavigate();
    // const [twitterHandle, setTwitterHandle] = useState('');
    // State for storing user public key
    const [userPublicKey, setUserPublicKey] = useState(localStorage.getItem('userPublicKey') || '');


    const [isSendModalOpen, setIsSendModalOpen] = useState(false);

    // This function is called when a click is detected outside the modal
    const handleClickOutsideModal = useCallback((event) => {
        // Check if the modal is open and if the click is outside the modal content
        const modalContent = document.querySelector('.send-modal-content');
        if (isSendModalOpen && modalContent && !modalContent.contains(event.target)) {
            setIsSendModalOpen(false); // Close the modal
        }
    }, [isSendModalOpen]);

    useEffect(() => {
        // Add event listener for clicks to handle closing the modal
        document.addEventListener('mousedown', handleClickOutsideModal);

        return () => {
            // Cleanup the event listener
            document.removeEventListener('mousedown', handleClickOutsideModal);
        };
    }, [handleClickOutsideModal]);

    const handleSendClick = () => {
        setIsSendModalOpen(true);
    };

    const handleModalClose = () => {
        setIsSendModalOpen(false);
    };

    const handleSendSolana = async (amount, recipientPublicKey, password) => {
        const userPublicKey = localStorage.getItem('userPublicKey');

        // Start by constructing the fetch options
        const fetchOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                publicKey: userPublicKey,
                password: password,
                amount: amount,
                recipientPublicKey: recipientPublicKey,
            }),
        };

        try {
            // Perform the fetch operation to your API endpoint
            const response = await fetch(`${apiUrl}/api/send-solana`, fetchOptions);

            // Parse the JSON response
            const data = await response.json();

            // Check for a successful response
            if (!response.ok) {
                // If the response is not successful, throw an error with the message from the response
                throw new Error(data.message || 'Error sending Solana');
            }

            // If the response is successful, log the transaction ID
            console.log("Transaction successful with ID:", data.transactionId);

            // Refresh the user's balance after a successful transaction
            await fetchBalance(userPublicKey);

            // Return the transaction ID to the calling function
            return data.transactionId;
        } catch (error) {
            // If there's an error, log it and rethrow to be handled by the calling function
            console.error("Transaction failed:", error.message);
            throw error;
        }
    };

    useEffect(() => {
        // Now the userPublicKey is defined before its use in useEffect
        const fetchUserData = async () => {
            try {
                const response = await fetch(`${apiUrl}/api/userdata-by-publickey/${userPublicKey}`);
                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
                // const data = await response.json();
                // setTwitterHandle(data.twitterHandle);
                // Update other states as needed with the fetched data
            } catch (error) {
                console.error("Error fetching user data:", error.message);
            }
        };

        if (userPublicKey) {
            fetchUserData();
        }
    }, [userPublicKey]); // Depend on userPublicKey


    // State for QR code popup visibility and user public key
    const [isQrPopupVisible, setQrPopupVisible] = useState(false);

    const [clipboardMessage, setClipboardMessage] = useState('');

    // Function to copy the public address to the clipboard
    const copyToClipboard = () => {
        navigator.clipboard.writeText(userPublicKey).then(() => {
            // Update the state to show the message
            setClipboardMessage('Address copied to clipboard!');
            // Clear the message after 3 seconds
            setTimeout(() => setClipboardMessage(''), 3000);
        }, (err) => {
            console.error('Could not copy text: ', err);
            setClipboardMessage('Failed to copy address.');
            setTimeout(() => setClipboardMessage(''), 3000);
        });
    };

    // Function to toggle QR code popup
    const toggleQrPopup = () => {
        setQrPopupVisible(!isQrPopupVisible);
    };

    // Function to close QR code popup when clicking outside
    const handleClickOutside = useCallback((event) => {
        const qrPopup = document.querySelector('.qr-popup');
        if (qrPopup && !qrPopup.contains(event.target)) {
            setQrPopupVisible(false);
        }
    }, []);

    useEffect(() => {
        // Add event listener for clicks
        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            // Cleanup the event listener
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [handleClickOutside]);


    //Function to DecryptPrivateKey
    const decryptPrivateKey = () => {
        setQrPopupVisible(!isQrPopupVisible);
    };

    // Redirect to login page if user is not logged in
    useEffect(() => {
        const storedPublicKey = localStorage.getItem('userPublicKey');

        if (!storedPublicKey) {
            // Redirect to login if no userPublicKey is found
            navigate('/walletloginpage');
        } else {
            setUserPublicKey(storedPublicKey);
            // You can also fetch the balance and user profile here if needed
        }
    }, [navigate]);

    // Assuming you have a state defined at the top of your Wallet component
    const [userProfile, setUserProfile] = useState({
        // Default values, possibly replaced with actual data fetched from the backend
        photo: '', // Path to the user's profile photo
        // ...other user profile properties
    });

    // Fetch user profile data on component mount
    useEffect(() => {
        // Replace '/api/user-profile' with the actual endpoint to fetch user data
        fetch(`${apiUrl}/api/user-profile`)
            .then(response => response.json())
            .then(data => {
                setUserProfile(data);
            })
            .catch(error => console.error('Error fetching user profile:', error));
    }, []); // Empty dependency array to run only once on mount

    const handleIconClick = (iconName) => {
        if (iconName === 'home') {
            // Logic to navigate to the home page
            navigate('/games');
        } else if (iconName === 'wallet') {
            // Logic to navigate to the marketplace
            navigate('/wallet');
        } else if (iconName === 'profile') {
            // Logic to navigate to the user profile
            navigate('/profile');
        }
    };

    // Check if the user is logged in
    useEffect(() => {
        // Check if public key is stored in local storage
        const storedPublicKey = localStorage.getItem('userPublicKey');

        // If there is no public key, redirect to login
        if (!storedPublicKey) {
            navigate('/walletloginpage');
        } else {
            // If there is a publicKey, set it to state and fetch balance
            setUserPublicKey(storedPublicKey);
            fetchBalance(storedPublicKey);
        }
    }, [navigate, userPublicKey]);

    const fetchBalance = (publicKey) => {
        fetch(`${apiUrl}/api/wallet-balance/${publicKey}`)
            .then(response => {
                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
                return response.json();
            })
            .then(data => {
                setBalance(data.balance);
            })
            .catch(error => {
                console.error('Error fetching balance:', error);
            });
    };

    // Toggle between light and dark mode
    const toggleTheme = () => {
        setIsDarkMode(!isDarkMode);
    };

    // Update the class on the body element for dark mode
    useEffect(() => {
        document.body.className = isDarkMode ? 'dark-mode' : '';
    }, [isDarkMode]);

    const toggleBalanceVisibility = () => {
        setShowBalance(!showBalance);
    };

    // Render wallet page or redirect to login
    return (
        <div className={`wallet-container ${isDarkMode ? 'dark-mode' : ''}`}>
            <div className="logo-container">
                <img src={logoImage} alt="Game Logo" className="game-logo" />
            </div>
            <button className="theme-toggle-btn" onClick={toggleTheme}>
                {isDarkMode ? '🌞' : '🌜'}
            </button>
            <div className="main-content">
                <div className="balance-heading-container">
                    <h2>Current Balance | <button className="toggle-balance" onClick={toggleBalanceVisibility}>
                        {showBalance ? '🫣' : '👀'}
                    </button></h2>
                </div>
                <div className="balance-amount-container">
                    {showBalance ? `${balance.toFixed(5)} SOL` : '••••••'}
                </div>
                <div className="wallet-actions">
                    <button className="action-button send-button" title="Send" onClick={handleSendClick}>
                        <FaPaperPlane size={40} /><div className="send-txt">Send</div>
                    </button>
                    <button className="action-button receive-button" title="Receive">
                        <FaDownload size={40} /> <div className="receive-txt" onClick={toggleQrPopup}> Receive</div>
                    </button>
                    <SendModal
                        isOpen={isSendModalOpen}
                        onClose={handleModalClose}
                        onSend={handleSendSolana}
                    />
                </div>
                <div className="wallet-address">
                    <div className="wallet-address-header">
                        <p>Wallet Address</p>
                        <div className="address-info">
                            <button onClick={decryptPrivateKey}>
                                <FaUnlock />
                            </button>
                            <button onClick={toggleQrPopup}>
                                <FaQrcode />
                            </button>
                            <button onClick={copyToClipboard}>
                                <FaCopy />
                            </button>
                        </div>
                    </div>
                    <div className="wallet-address-content">
                        {userPublicKey}
                    </div>
                    {clipboardMessage && (
                        <div className="clipboard-message">{clipboardMessage}</div>
                    )}
                    {isQrPopupVisible && (
                        <div className="qr-popup active" onClick={(e) => e.stopPropagation()}>
                            <QRCode value={userPublicKey} size={256} />
                            <button onClick={toggleQrPopup}>Close</button>
                        </div>
                    )}
                </div>
                <div className="crypto-assets">
                    {/* <h3>SWG Coin</h3> */}
                    {/* Display SWG coin amount */}
                </div>
            </div>
            {/* The NFT assets section can be added here if needed */}
            <Footer isDarkMode={isDarkMode} userProfile={userProfile} onIconClick={handleIconClick} />
        </div>
    );
};

export default Wallet;
