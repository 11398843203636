import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import './DashboardPage.css';
import WalletModal from '../components/WalletModal';

const apiUrl = process.env.REACT_APP_API_URL;

const DashboardPage = () => {
    const [userData, setUserData] = useState(null);
    const [error, setError] = useState('');
    const navigate = useNavigate();
    const [isWalletModalOpen, setIsWalletModalOpen] = useState(false);

    useEffect(() => {
        // Check if the user is logged in
        const token = localStorage.getItem('token');
        if (!token) {
            // No token found, redirect to login page
            navigate('/loginpage');
        } else {
            // Fetch user data if the token exists
            const fetchUserData = async () => {
                try {
                    const response = await axios.get(`${apiUrl}/api/userdata`, {
                        headers: {
                            Authorization: `Bearer ${token}`
                        }
                    });

                    setUserData(response.data);
                } catch (err) {
                    console.error('Error fetching user data:', err);
                    setError('Failed to load user data. Please try again.');
                    // Clear the token and redirect to login page
                    localStorage.removeItem('token');
                    navigate('/loginpage');
                }
            };
            fetchUserData();
        }
    }, [navigate]);

    const hasWallet = Boolean(userData && userData.hasWallet);

    const handleLogout = () => {
        localStorage.removeItem('token'); // Remove the token
        navigate('/loginpage'); // Redirect to login page
    };

    const handleMyWallet = () => {
        setIsWalletModalOpen(true);
    };

    const handleAvailableTasks = () => {
        navigate('/tasks');
    };

    if (error) {
        return <div className="dashboard-error">Error: {error}</div>;
    }

    if (!userData) {
        return <div className="dashboard-loading">Loading...</div>;
    }

    return (
        <div className="dashboard-page">
            <div className="dashboard-container">
                <h1>User Dashboard</h1>
                <p>Twitter Handle: {userData.twitterHandle}</p>
                <p>Solana Address: {userData.solanaAddress}</p>
                <p>Points: {
                    userData.points}</p>
                {/* Other user data here */}
                <button onClick={handleMyWallet} className="dashboard-button">My Wallet</button>
                {userData && (
                    <WalletModal
                        isOpen={isWalletModalOpen}
                        onClose={() => setIsWalletModalOpen(false)}
                        hasWallet={hasWallet}
                        setUserPublicKey={(publicKey) => setUserData({ ...userData, solanaAddress: publicKey })} // Update the state with the new public key
                        twitterHandle={userData.twitterHandle}
                    />
                )}
                <button onClick={handleAvailableTasks} className="dashboard-button">Available Tasks</button>
                <button onClick={handleLogout} className="dashboard-logout-button">Logout</button>
            </div>
        </div>
    );
};

export default DashboardPage;