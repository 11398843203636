import React from 'react';
import './Footer.css'; 
import { FaTelegramPlane, FaTwitter } from 'react-icons/fa';

const Footer = () => {
  return (
    <footer className="footer">
      <p>&copy; {new Date().getFullYear()} ExoCrest Labs</p>
      <div className="social-media">
        <a href="https://twitter.com/exocrest" className="social-icon twitter-icon">
          <FaTwitter />
        </a>
        <a href="https://t.me/YourTelegramHandle" className="social-icon telegram-icon">
          <FaTelegramPlane />
        </a>
      </div>
    </footer>
  );
};

export default Footer;
