import React, { useState, useEffect } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import './App.css';
import Navbar from './components/Navbar';
import HeroSection from './components/HeroSection';
// import Faq from './components/Faq';
// import FeatureCards from './components/FeaturesCard';
// import TokenSection from './components/TokenSection';
// import MissionsSection from './components/MissionsSection';
import Footer from './components/Footer';
import AboutPage from './pages/AboutPage';
import ComingSoon from './pages/ComingSoon';
import AboutTokenPage from './pages/AboutTokenPage';
import AirdropPage from './pages/AirdropPage';
import LoginPage from './pages/LoginPage';
import DashboardPage from './pages/DashboardPage';
import Wallet from './game/Wallet';
import WalletLoginPage from './game/WalletLoginPage';
import Games from './game/Games';
import Loader from './components/Loader';

function App() {
  const [loading, setLoading] = useState(true);
  const location = useLocation();
  const noNavbarRoutes = ['/wallet', '/walletloginpage', '/games'];

  useEffect(() => {
    // Simulate loading time with setTimeout
    // Replace this with your actual loading logic
    setTimeout(() => setLoading(false), 400);
  }, []);

  if (loading) {
    return <Loader />;
  }

  return (
    <div className="App">
      {!noNavbarRoutes.includes(location.pathname) && <Navbar />}
      <Routes>
        <Route path="/" element={
          <>
            <HeroSection />
            {/* <Faq /> */}
            {/* <FeatureCards /> */}
            {/* <TokenSection /> */}
            {/* <MissionsSection /> */}
            <Footer />
          </>
        } />
        <Route path="/about" element={<AboutPage />} />
        <Route path="/comingsoon" element={<ComingSoon />} />
        <Route path="/abouttoken" element={<AboutTokenPage />} />
        <Route path="/airdroppage" element={<AirdropPage />} />
        <Route path="/loginpage" element={<LoginPage />} />
        <Route path="/dashboard" element={<DashboardPage />} />
        <Route path="/wallet" element={<Wallet />} />
        <Route path="/walletloginpage" element={<WalletLoginPage />} />
        <Route path="/games" element={<Games />} />
        {/* Define other routes as needed */}
      </Routes>
    </div>
  );
}

export default App;
