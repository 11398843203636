import React from 'react';
import './AboutTokenPage.css';
import gameBackground from '../img/planet6.png'; // Replace with the path to your game image

const AboutTokenPage = () => {
    return (
        <div className="about-token-page" style={{ backgroundImage: `url(${gameBackground})` }}>
            <div className="content-container">
                <h1 className="gradient-text">The Game Token</h1>

                <div className="token-details">
                    <h2 className="gradient-text">Token Name:</h2>
                    <p>ExoCredits</p>

                    <h2 className="gradient-text">Token Ticker:</h2>
                    <p>EXCR</p>

                    <h2 className="gradient-text">Total/Max Supply:</h2>
                    <p>1 billion</p>

                    <h2 className="gradient-text">Allocation:</h2>
                    <ul>
                        <li>50% Gameplay Incentives</li>
                        <li>15% Community and Marketing</li>
                        <li>15% Development</li>
                        <li>10% Strategic Partnerships</li>
                        <li>5% Development Team</li>
                        <li>5% Reserve Fund</li>
                    </ul>

                    <h2 className="gradient-text">Utility:</h2>
                    <ul>
                        <li>In-Game Transactions: Used for land, resources and upgrades.</li>
                        <li>Governance: Token holders can vote on game updates.</li>
                        <li>Staking: Earn rewards and contribute to liquidity.</li>
                        <li>Deflationary Mechanisms: A percentage of EXCR tokens are burnt weekly. After game launch.</li>
                    </ul>

                    <h2 className="gradient-text">Earning Mechanisms:</h2>
                    <ul>
                        <li>Resource Management: Earn tokens through effective management and trading.</li>
                        <li>Quests and Competitions: Complete challenges for rewards.</li>
                    </ul>

                    <h2 className="gradient-text">Spending Mechanisms:</h2>
                    <ul>
                        <li>Land Auctions: Bid for prime real estate with tokens.</li>
                        <li>Customizations: Customize country's appearance/features.</li>
                        <li>Marketplace: Trade in-game assets and resources.</li>
                    </ul>

                    <h2 className="gradient-text">Liquidity and Exchange:</h2>
                    <ul>
                        <li>Liquidity Pools: Established on decentralized exchanges.</li>
                        <li>Exchange Listings: Accessible on popular exchanges.</li>
                    </ul>

                    <h2 className="gradient-text">Rewards:</h2>
                    <ul>
                        <li>Play-to-Earn: Reward system for active players.</li>
                    </ul>
                </div>
            </div>
        </div>
    );
};

export default AboutTokenPage;
