import React from 'react';

const features = [
  { title: 'Buy Land', description: 'Purchase the foundation of your empire.', link: '/comingsoon' },
  { title: 'Form Government', description: 'Establish the ruling body of your country.', link: '/comingsoon' },
  { title: 'Marketplace', description: 'Trade resources and expand your economy.', link: '/comingsoon' },
  // Add more features as needed
];

const FeatureCards = () => {
  return (
    <div className="feature-cards">
      {features.map((feature, index) => (
        // Use an `a` tag or `Link` from `react-router-dom` if you're using routing
        <a href={feature.link} className="card" key={index}> 
          <h3>{feature.title}</h3>
          <p>{feature.description}</p>
        </a>
      ))}
    </div>
  );
};

export default FeatureCards;
