import React, { useState, useEffect } from 'react';
import './ComingSoon.css';
import { FaTelegram, FaTwitter } from 'react-icons/fa';
import gameBackground from '../img/planet7.png';

const calculateTimeLeft = () => {
    const difference = +new Date('2024-02-02') - +new Date();
    let timeLeft = {};

    if (difference > 0) {
        timeLeft = {
            days: Math.floor(difference / (1000 * 60 * 60 * 24)),
            hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
            minutes: Math.floor((difference / 1000 / 60) % 60),
            seconds: Math.floor((difference / 1000) % 60),
        };
    }

    return timeLeft;
};

const ComingSoon = () => {
    const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

    useEffect(() => {
        const timer = setTimeout(() => {
            setTimeLeft(calculateTimeLeft());
        }, 1000);

        // Clear timeout if the component is unmounted
        return () => clearTimeout(timer);
    });

    const timerComponents = [];

    Object.keys(timeLeft).forEach((interval) => {
        if (!timeLeft[interval]) {
            return;
        }

        timerComponents.push(
            <span key={interval}>
                {timeLeft[interval]} {interval}{" "}
            </span>
        );
    });

    return (
        <div className="coming-soon-page" style={{ backgroundImage: `url(${gameBackground})` }}>
            <div className="overlay">
                <h1 className="gradient-text">Coming Soon</h1>
                <div className="countdown-container">
                    <h2 className="gradient-text">Launching 2nd February 2024</h2>
                    <div className="countdown">
                        {timerComponents.length ? timerComponents : <span>Time's up!</span>}
                    </div>
                </div>
                <div className="buttons-container">
                    <a href="/airdroppage" className="btn airdrop-button">EXCR Airdrop</a>
                    <div className="horizontal-buttons">
                        <a href="/buy-token" className="btn buy-token">Buy Token (EXCR)</a>
                        <a href="/abouttoken" className="btn about-token">About the Token</a>
                    </div>
                    <div className="social-buttons">
                        <a href="https://twitter.com/exocrest" className="btn social-media-icon">
                            <FaTwitter size={30} />
                        </a>
                        <a href="https://t.me/YourGameTelegramHandle" className="btn telegram-media-icon">
                            <FaTelegram size={30} />
                        </a>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ComingSoon;
