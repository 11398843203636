import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import './WalletLoginPage.css';
import logoImage from '../img/Logo-horizontal.png';

const apiUrl = process.env.REACT_APP_API_URL;

const WalletLoginPage = () => {
    const [twitterUsername, setTwitterUsername] = useState('');
    const [password, setPassword] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const navigate = useNavigate();
    const [isDarkMode, setIsDarkMode] = useState(true);
    const [showPassword, setShowPassword] = useState(false);

    // On component mount, check for userPublicKey
    useEffect(() => {
        const userPublicKey = localStorage.getItem('userPublicKey');
        if (userPublicKey) {
            console.log('User is already logged in with publicKey:', userPublicKey);
            navigate('/games');
        }
        // This will only run once on component mount due to the empty dependency array
    }, [navigate]);

    const handleLogin = async (event) => {
        event.preventDefault();

        if (!twitterUsername || !password) {
            setErrorMessage('Please enter both Twitter Username and Wallet Password.');
            setTimeout(() => setErrorMessage(''), 3000);
            return;
        }

        try {
            const response = await axios.post(`${apiUrl}/api/login-wallet`, {
                twitterHandle: twitterUsername,
                password
            });

            // After successful login
            localStorage.setItem('jwtToken', response.data.token);
            localStorage.setItem('userPublicKey', response.data.publicKey);
            navigate('/games');
            console.log('Wallet login successful:', response.data);

            console.log('Wallet login successful:', response.data);
        } catch (error) {
            setErrorMessage('Login failed. Please check your credentials.');
            setTimeout(() => {
                setErrorMessage('');
            }, 3000); // Error message will disappear after 3 seconds
            console.error('Wallet login error:', error);
        }
    };

    const handleCreateNewWallet = () => {
        navigate('/create-wallet'); // Navigate to the wallet creation page
    };

    const toggleTheme = () => {
        const newTheme = !isDarkMode ? 'dark' : 'light';
        setIsDarkMode(!isDarkMode);
        localStorage.setItem('theme', newTheme);
    };

    const handlePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    // Apply the dark-mode class to the div if isDarkMode is true
    const pageClass = `wallet-login-page ${isDarkMode ? 'dark-mode' : ''}`;

    return (
        <div className={pageClass}>
            <button className="theme-toggle-btn" onClick={toggleTheme}>
                {isDarkMode ? '🌞' : '🌜'}
            </button>
            <img src={logoImage} alt="Game Logo" className="login-logo" />
            <h1 className="welcome-back">👋 Welcome back!</h1>
            <p className="slogan">Build and manage virtual countries in the metaverse</p>
            {errorMessage && <div className="error-message">{errorMessage}</div>}
            <form className="wallet-login-container" onSubmit={handleLogin}>
                <div className="input-group">
                    <label>Twitter username</label>
                    <input
                        type="text"
                        placeholder="Your Twitter username"
                        value={twitterUsername}
                        onChange={(e) => setTwitterUsername(e.target.value)}
                    />
                </div>
                <div className="input-group">
                    <label>Password</label>
                    <input
                        type={showPassword ? 'text' : 'password'}
                        placeholder="Your wallet password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                    />
                    <span className="password-toggle" onClick={handlePasswordVisibility}>
                        {showPassword ? '🫣' : '👀'}
                    </span>
                </div>
                <div className="form-footer">
                    <div className="checkbox-group">
                        <input type="checkbox" id="keep-signed-in" />
                        <label htmlFor="keep-signed-in">Keep me signed in</label>
                    </div>
                    {/* <a href="#" className="forgot-password">forgot password?</a> */}
                </div>
                <button type="submit" className="login-btn">Login</button>
                <p className="register-text">
                    Don't have an account? <span className="register-link" onClick={handleCreateNewWallet}>Create new</span>
                </p>
            </form>
            <button className="theme-toggle-btn" onClick={toggleTheme}>
                {isDarkMode ? '🌞' : '🌜'}
            </button>
        </div>
    );
};

export default WalletLoginPage;
