import React, { useState } from 'react';
import './AirdropPage.css';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import { Helmet } from 'react-helmet';
import 'react-toastify/dist/ReactToastify.css';

const apiUrl = process.env.REACT_APP_API_URL;

const AirdropPage = () => {
    const [twitterHandle, setTwitterHandle] = useState('');
    const [telegramHandle, setTelegramHandle] = useState('');
    const [twitterLink, setTwitterLink] = useState('');
    const [solanaAddress, setSolanaAddress] = useState('');
    const [isTwitterFollowed, setIsTwitterFollowed] = useState(false);
    const [isTelegramJoined, setIsTelegramJoined] = useState(false);
    const [isTweetSubmitted, setIsTweetSubmitted] = useState(false);
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const API_URL = `${apiUrl}/api/users`;
    const notify = (message) => toast(message);

    const handleAction = (actionType) => {
        switch (actionType) {
            case 'twitterFollow':
                if (!isTwitterFollowed) {
                    window.open('https://twitter.com/YOUR_TWITTER_ACCOUNT', '_blank');
                    setIsTwitterFollowed(true);
                    notify('100 points awarded for following on Twitter!');
                } else {
                    notify('You have already followed on Twitter!');
                }
                break;
            case 'telegramJoin':
                if (!isTelegramJoined) {
                    window.open('https://t.me/YOUR_TELEGRAM_GROUP', '_blank');
                    setIsTelegramJoined(true);
                    notify('100 points awarded for joining Telegram group!');
                } else {
                    notify('You have already joined the Telegram group!');
                }
                break;
            case 'tweet':
                if (!isTweetSubmitted && twitterLink.trim() !== '') {
                    setIsTweetSubmitted(true);
                    notify('1000 points awarded for tweeting!');
                } else if (isTweetSubmitted) {
                    notify('Tweet already submitted!');
                } else {
                    notify('Please provide a link to your tweet.');
                }
                break;
            default:
                break;
        }
    };

    const openTwitterToTweet = () => {
        const tweetContent = encodeURIComponent("Check out ExoCrest, an exciting new game in the metaverse! #ExoCrest #GameFi");
        window.open(`https://twitter.com/intent/tweet?text=${tweetContent}`, '_blank');
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!isTwitterFollowed || !isTelegramJoined || !isTweetSubmitted || twitterLink.trim() === '' || solanaAddress.trim() === '') {
            notify('Please complete all actions and provide your Solana address.');
            return;
        }
        setIsLoading(true);
        try {
            const points = calculatePoints();
            const response = await axios.post(API_URL, {
                twitterHandle,
                telegramHandle,
                tweetLink: twitterLink,
                solanaAddress,
                points
            });
            if (response.status === 201) {
                setIsSubmitted(true);
                notify('Submission successful! Your actions have been recorded.');
            }
        } catch (error) {
            console.error("Error submitting data", error);
            notify('Error submitting data.');
        }
        setIsLoading(false);
    };

    const calculatePoints = () => {
        let totalPoints = 0;
        if (isTwitterFollowed) totalPoints += 100;
        if (isTelegramJoined) totalPoints += 100;
        if (isTweetSubmitted) totalPoints += 1000;
        return totalPoints;
    };

    return (
        <div className="airdrop-page">

            <Helmet>
                <title>EXCR Token Airdrop</title>
                <meta name="description" content="Join our exclusive EXCR Token Airdrop! Earn tokens by participating in our community activities." />
                <meta name="image" content="http://example.com/path-to-your-airdrop-image.jpg" />
                {/* Twitter Card Meta Tags */}
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:site" content="@exocrest" />
                <meta name="twitter:title" content="EXCR Token Airdrop" />
                <meta name="twitter:description" content="Participate in the EXCR Token Airdrop and get rewarded! Join our community now." />
                <meta name="twitter:image" content="http://example.com/path-to-your-twitter-airdrop-image.jpg" />
                {/* Add additional meta tags as needed */}
            </Helmet>

            <ToastContainer />
            <div className="airdrop-container">
                <h1>Join the EXCR Token Airdrop</h1>
                <p>Engage with us & earn your rewards!</p>
                {!isSubmitted ? (
                    <form onSubmit={handleSubmit}>
                        <div className="input-container">
                            <input
                                type="text"
                                placeholder="Enter your Twitter Handle"
                                value={twitterHandle}
                                onChange={(e) => setTwitterHandle(e.target.value)}
                            />
                            <button type="button" onClick={() => handleAction('twitterFollow')}>Follow on Twitter</button>

                            <input
                                type="text"
                                placeholder="Enter your Telegram Handle"
                                value={telegramHandle}
                                onChange={(e) => setTelegramHandle(e.target.value)}
                            />
                            <button type="button" onClick={() => handleAction('telegramJoin')}>Join Telegram Group</button>

                            <input
                                type="url"
                                placeholder="Link to your Tweet"
                                value={twitterLink}
                                onChange={(e) => setTwitterLink(e.target.value)}
                            />
                            <div className="tweet-actions">
                                <button type="button" onClick={openTwitterToTweet}>Tweet About Us</button>
                                <button type="button" onClick={() => handleAction('tweet')}>Submit Tweet</button>
                            </div>

                            <input
                                type="text"
                                placeholder="Your Solana Address"
                                value={solanaAddress}
                                onChange={(e) => setSolanaAddress(e.target.value)}
                            />
                        </div>
                        <div className="submit-container">
                            <button type="submit" className="submit-button" disabled={isLoading}>
                                {isLoading ? "Processing..." : "Claim Airdrop"}
                            </button>
                        </div>
                    </form>
                ) : (
                    <div className="thank-you-message">
                        <p>Thank you for participating in the EXCR Token Airdrop!</p>
                        <p>Your total points: {calculatePoints()}</p>
                    </div>
                )}
                <div className="dashboard-text">
                    To view your Airdrop Dashboard
                </div>
                <button className="dashboard-login-button" onClick={() => window.location.href = '/loginpage'}>
                    Login to Airdrop Dashboard
                </button>
            </div>
        </div>
    );
};

export default AirdropPage;              
