import React from 'react';
import './AboutPage.css';
import backgroundImage from '../img/planet5.png';
import logoImage from '../img/Logo-horizontal.png';
import { FaTelegram } from 'react-icons/fa';

const AboutPage = () => {
    return (
        <div className="about-page" style={{ backgroundImage: `url(${backgroundImage})` }}>
            <div className="about-page-shade">
                <section className="about-hero">
                    <div className="welcome-container">
                        <h1 className="gradient-text">Welcome to</h1>
                        <img src={logoImage} alt="ExoCrest Logo" className="game-logo" />
                    </div>
                    <p className="gradient-text">Embark on a journey to build and manage virtual countries in a vast metaverse.</p>
                </section>

                <section className="about-details">
                    <div className="about-text">
                        <h2 className="gradient-text">About ExoCrest</h2>
                        <br></br>
                        <p>
                            ExoCrest is an immersive metaverse experience built on the Solana blockchain, allowing players to create,
                            control, and expand their virtual countries. With future plans to go multichain, ExoCrest combines
                            blockchain mechanics with a rich strategy game experience.
                        </p>
                        <br></br>
                        <p>
                            Players begin their journey by acquiring land, which can be bought as NFTs with SOL, (Solana blockchain's local currency). With land as the foundation,
                            players can form a government, manage resources, and make decisions to guide the prosperity of their nations.
                        </p>
                    </div>
                    <div className="about-image">
                        {/* Assuming you have another image here */}
                    </div>
                </section>

                <section className="about-features">
                    <h2 className="gradient-text">Game Features</h2>
                    <ul>
                        <li>Create and name your own country in the metaverse</li>
                        <li>Buy land and expand your territory</li>
                        <li>Form alliances with other players or wage war to conquer new lands</li>
                        <li>Build houses, factories, farms, and more to strengthen your nation</li>
                        <li>Trade resources in a dynamic global market</li>
                        <li>Earn currency through gameplay or exchange with SOL</li>
                    </ul>
                </section>
                <section className="game-mechanics">
                    <h3 className="gradient-text">Blockchain Mechanics</h3>
                    <ul>
                        <li>Ownership of virtual land and assets through NFTs</li>
                        <li>Staking in-game currency to earn resources like wood, stone, and minerals</li>
                        <li>Decentralized governance giving players a voice in game evolution</li>
                        <li>Trade resources and assets on an in-game decentralized market</li>
                    </ul>
                    <h3 className="gradient-text">Core Game Mechanics</h3>
                    <ul>
                        <li>Build and manage your country's infrastructure and economy</li>
                        <li>Engage in diplomacy or warfare with other nations</li>
                        <li>Complete quests and missions to strengthen your nation</li>
                        <li>Explore the vast metaverse for new opportunities</li>
                    </ul></section>
                <section className="about-join">
                    <h2 className="gradient-text">Join the ExoCrest Community</h2>
                    <p>Ready to claim your place in the universe? Join ExoCrest now and start building your legacy.</p>
                    <br></br>
                    <button className="join-button"> <FaTelegram size={15} color="#fff" /> Join Telegram</button>
                </section>
            </div>
        </div>
    );
};

export default AboutPage;
