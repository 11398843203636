import React from 'react';
import './Footer.css';
import { FaHouseUser, FaWallet } from 'react-icons/fa';
import defaultImage from '../img/logo.png'

const Footer = ({ isDarkMode, userProfile, onIconClick }) => {
    const defaultProfilePhoto = defaultImage; // Replace with the actual path to the default photo
    const profilePhoto = userProfile.photo || defaultProfilePhoto;

    return (
        <footer className={`footer ${isDarkMode ? 'dark-mode' : 'light-mode'}`}>
            <div className="footer-icon emoji" onClick={() => onIconClick('home')}>
            <FaHouseUser size={30} />
            </div>
            <div className="footer-icon emoji" onClick={() => onIconClick('wallet')}>
            <FaWallet size={30} />
            </div>
            <div className="footer-profile-icon profile-icon" onClick={() => onIconClick('profile')}>
                <img src={profilePhoto} alt="Profile" />
            </div>
        </footer>
    );
};

export default Footer;
