import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './Games.css';
import Footer from './Footer'; // Assuming this is the same footer from the wallet page
import logoImage from '../img/Logo-horizontal.png'; // Reuse the logo image from the wallet page
const apiUrl = process.env.REACT_APP_API_URL;

const Games = () => {
    const [isDarkMode, setIsDarkMode] = useState(true); // Dark mode as default
    const [balance, setBalance] = useState(0); // Assuming balance is fetched or passed as a prop
    const [showBalance, setShowBalance] = useState(true);
    const [userPublicKey, setUserPublicKey] = useState(localStorage.getItem('userPublicKey') || '');
    const navigate = useNavigate();

    // Redirect to login page if user is not logged in
    useEffect(() => {
        const storedPublicKey = localStorage.getItem('userPublicKey');

        if (!storedPublicKey) {
            // Redirect to login if no userPublicKey is found
            navigate('/walletloginpage');
        } else {
            setUserPublicKey(storedPublicKey);
            // You can also fetch the balance and user profile here if needed
        }
    }, [navigate]);

    const handleIconClick = (iconName) => {
        if (iconName === 'home') {
            // Logic to navigate to the home page
            navigate('/games');
        } else if (iconName === 'wallet') {
            // Logic to navigate to the marketplace
            navigate('/wallet');
        } else if (iconName === 'profile') {
            // Logic to navigate to the user profile
            navigate('/profile');
        }
    };

    // Assuming you have a state defined at the top of your Wallet component
    const [userProfile, setUserProfile] = useState({
        // Default values, possibly replaced with actual data fetched from the backend
        photo: '', // Path to the user's profile photo
        // ...other user profile properties
    });

    // Fetch user profile data on component mount
    useEffect(() => {
        // Replace '/api/user-profile' with the actual endpoint to fetch user data
        fetch(`${apiUrl}/api/user-profile`)
            .then(response => response.json())
            .then(data => {
                setUserProfile(data);
            })
            .catch(error => console.error('Error fetching user profile:', error));
    }, []); // Empty dependency array to run only once on mount

    // Check if the user is logged in
    useEffect(() => {
        // Check if public key is stored in local storage
        const storedPublicKey = localStorage.getItem('userPublicKey');

        // If there is no public key, redirect to login
        if (!storedPublicKey) {
            navigate('/walletloginpage');
        } else {
            // If there is a publicKey, set it to state and fetch balance
            setUserPublicKey(storedPublicKey);
            fetchBalance(storedPublicKey);
        }
    }, [navigate, userPublicKey]);

    const fetchBalance = (publicKey) => {
        fetch(`${apiUrl}/api/wallet-balance/${publicKey}`)
            .then(response => {
                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
                return response.json();
            })
            .then(data => {
                setBalance(data.balance);
            })
            .catch(error => {
                console.error('Error fetching balance:', error);
            });
    };


    const toggleTheme = () => {
        setIsDarkMode(!isDarkMode);
    };

    const toggleBalanceVisibility = () => {
        setShowBalance(!showBalance);
    };

    // Placeholder functions for button actions
    const buyLand = () => {
        console.log('Buy Land clicked');
        navigate('/comingsoon');
        // Implement functionality or navigation
    };

    const createCountry = () => {
        console.log('Create Country clicked');
        navigate('/comingsoon');
        // Implement functionality or navigation
    };

    const buildOnCountry = () => {
        console.log('Build on Country clicked');
        navigate('/comingsoon');
        // Implement functionality or navigation
    };

    const goToWar = () => {
        console.log('Go to War clicked');
        navigate('/comingsoon');
        // Implement functionality or navigation
    };

    // Render the games page
    return (
        <div className={`games-container ${isDarkMode ? 'dark-mode' : ''}`}>
            <div className="logo-container">
                <img src={logoImage} alt="Game Logo" className="game-logo" />
            </div>
            <button className="theme-toggle-btn" onClick={toggleTheme}>
                {isDarkMode ? '🌞' : '🌜'}
            </button>
            <div className="balance-game-heading-container">
            <h2>Current Balance | <button className="toggle-balance" onClick={toggleBalanceVisibility}>
                        {showBalance ? '🫣' : '👀'}
                    </button></h2>
                <div className="balance-amount-container">
                    {showBalance ? `${balance.toFixed(5)} SOL` : '••••••'}
                </div>
            </div>
            <div className="game-actions">
                <button className="game-action-button" onClick={buyLand}>
                    Buy Land
                </button>
                <button className="game-action-button" onClick={createCountry}>
                    Create Country
                </button>
                <button className="game-action-button" onClick={buildOnCountry}>
                    Build on Country
                </button>
                <button className="game-action-button" onClick={goToWar}>
                    Go to War
                </button>
            </div>
            <Footer isDarkMode={isDarkMode} userProfile={userProfile} onIconClick={handleIconClick} />
        </div>
    );
};

export default Games;
