import React from 'react';
import { useNavigate } from 'react-router-dom';
import './HeroSection.css';
import planetImage from '../img/planet1.png';
import logoImage from '../img/Logo-horizontal.png';
import FeaturesCard from './FeaturesCard';

const HeroSection = () => {
    const navigate = useNavigate();

    const handleAirdropPageClick = () => {
        navigate('/airdroppage');
    };

    const handleBuyTokenClick = () => {
        navigate('/buy-token');
    };

    const handleAboutTokenClick = () => {
        navigate('/abouttoken');
    };

    return (
        <div className="hero-section" style={{ backgroundImage: `url(${planetImage})` }}>
            <div className="hero-shade">
                <img src={logoImage} alt="ExoCrest Logo" className="game-logo" />
                <p>Build and manage virtual countries in an expansive metaverse.</p>
                <FeaturesCard /> {/* Include the FeatureCards component */}
                <div className="buttons-container">
                    <button className="hero-button airdrop-button" onClick={handleAirdropPageClick}>EXCR Airdrop</button>
                    <div className="horizontal-buttons-container">
                        <button className="hero-button buy-token" onClick={handleBuyTokenClick}>Buy Token (EXCR)</button>
                        <button className="hero-button about-token" onClick={handleAboutTokenClick}>About the Token</button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default HeroSection;