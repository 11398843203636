import React, { useState } from 'react';
import './SendModal.css';

const SendModal = ({ isOpen, onClose, onSend }) => {
    const [amount, setAmount] = useState('');
    const [recipient, setRecipient] = useState('');
    const [password, setPassword] = useState('');
    const [loading, setLoading] = useState(false);
    const [transactionId, setTransactionId] = useState('');
    const [sendSuccess, setSendSuccess] = useState(false);
    // Generate the full URL for the transaction ID
    const solscanUrl = transactionId ? `https://solscan.io/tx/${transactionId}` : '';

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        try {
            // Call the onSend function and expect it to return the transaction ID
            const txId = await onSend(amount, recipient, password);
            setTransactionId(txId);
            setSendSuccess(true);
        } catch (error) {
            console.error('Transaction Error:', error);
            setSendSuccess(false); // Ensure sendSuccess is false in case of an error
        } finally {
            setLoading(false);
        }
    };

    const [copyButtonText, setCopyButtonText] = useState('Copy Transaction ID');

    const handleCopy = () => {
        navigator.clipboard.writeText(transactionId).then(() => {
            setCopyButtonText('Transaction ID Copied!'); // Change button text
            // Reset button text after 3 seconds
            setTimeout(() => {
                setCopyButtonText('Copy Transaction ID');
            }, 3000);
        });
    };

    if (!isOpen) return null;

    return (
        <div className="send-modal-overlay">
            <div className="send-modal-content">
                <span className="send-modal-close" onClick={onClose}>&times;</span>
                {loading && <div>Sending...</div>}
                {sendSuccess ? (
                    <div className="send-modal-success">
                    <p>Transaction Successful!</p>
                    {transactionId && (
                      <p>
                        ID: <a href={solscanUrl} target="_blank" rel="noopener noreferrer">{transactionId}</a>
                      </p>
                    )}
                    <button className="copy-btn" onClick={handleCopy}>{copyButtonText}</button>
                </div>
                ) : (
                    <form onSubmit={handleSubmit}>
                        <input 
                            type="number" 
                            placeholder="Amount" 
                            value={amount} 
                            onChange={(e) => setAmount(e.target.value)} 
                            required 
                        />
                        <input 
                            type="text" 
                            placeholder="Recipient's Recieve address" 
                            value={recipient} 
                            onChange={(e) => setRecipient(e.target.value)} 
                            required 
                        />
                        <input 
                            type="password" 
                            placeholder="Your Password" 
                            value={password} 
                            onChange={(e) => setPassword(e.target.value)} 
                            required 
                        />
                        <button type="submit">Send</button>
                    </form>
                )}
            </div>
        </div>
    );
};

export default SendModal;
