import React, { useState, useEffect } from 'react';
import './LoginPage.css';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const apiUrl = process.env.REACT_APP_API_URL;

const LoginPage = () => {
    const [twitterUsername, setTwitterUsername] = useState('');
    const [solanaAddress, setSolanaAddress] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const navigate = useNavigate();

    useEffect(() => {
        // Check if the user is already logged in
        const token = localStorage.getItem('token');
        if (token) {
            // Token exists in localStorage, redirect to dashboard
            navigate('/dashboard');
        }
    }, [navigate]); // Only re-run the effect if navigate changes

    const handleSubmit = async (event) => {
        event.preventDefault();
        if (!twitterUsername || !solanaAddress) {
            setErrorMessage('Please enter both Twitter Username and Solana Address.');
            setTimeout(() => setErrorMessage(''), 3000);
            return;
        }

        try {
            const response = await axios.post(`${apiUrl}/api/login`, {
                twitterHandle: twitterUsername,
                solanaAddress
            });
            localStorage.setItem('token', response.data.token); // Store the token
            navigate('/dashboard'); // Redirect to dashboard
            console.log('Login successful:', response.data);
        } catch (error) {
            setErrorMessage('Login failed. Please check your credentials.');
            setTimeout(() => {
                setErrorMessage('');
            }, 3000); // Error message will disappear after 3 seconds
            console.error('Login error:', error);
        }
    };

    const handleGetStarted = () => {
        navigate('/airdroppage'); // Navigate to the airdrop page
    };

    return (
        <div className="login-page">
            <form className="login-container" onSubmit={handleSubmit}>
                <h2>Login to Your Dashboard</h2>
                {errorMessage && <p className="error-message">{errorMessage}</p>}
                <input
                    type="text"
                    placeholder="Twitter Username"
                    value={twitterUsername}
                    onChange={(e) => setTwitterUsername(e.target.value)}
                />
                <input
                    type="text"
                    placeholder="Solana Address"
                    value={solanaAddress}
                    onChange={(e) => setSolanaAddress(e.target.value)}
                />
                <button type="submit">Login</button>
                <button type="button" className="get-started-button" onClick={handleGetStarted}>Get Started</button>
            </form>
        </div>
    );
};

export default LoginPage;
