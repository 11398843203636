import React, { useState } from 'react';
import axios from 'axios';
import './WalletModal.css';
import { useNavigate } from 'react-router-dom';
import logo from '../img/Logo-horizontal.png';

const apiUrl = process.env.REACT_APP_API_URL;

const WalletModal = ({ isOpen, onClose, hasWallet, setUserPublicKey, twitterHandle }) => {
    const [password, setPassword] = useState('');
    const navigate = useNavigate();
    const [error, setError] = useState('');


    if (!isOpen) return null;

    const handleCreateWallet = async () => {
        if (!password) {
            setError('Please enter a password.');
            setTimeout(() => setError(''), 3000); // Clear the error after 3 seconds
            return;
        }

        console.log('Creating wallet for Twitter username:', twitterHandle);

        try {
            const response = await axios.post(`${apiUrl}/api/create-wallet`, {
                twitterHandle,
                password
            });
            const { publicKey } = response.data;
            if (setUserPublicKey) {
                setUserPublicKey(publicKey); // Set the user's public key in the parent state
            }
            onClose();
            navigate('/wallet'); // Navigate to the Wallet page
        } catch (err) {
            console.error('Error creating wallet:', err);
            setError('An error occurred while creating the wallet. Please try again later.');
            setTimeout(() => setError(''), 3000); // Clear the error after 3 seconds
        }
    };

    const handleLoginWallet = async () => {
        if (!password) {
            setError('Please enter a password.');
            setTimeout(() => setError(''), 3000); // Clear the error after 3 seconds
            return;
        }
        try {
            const response = await axios.post(`${apiUrl}/api/login-wallet`, {
                twitterHandle,
                password
            });
            if (response.data.publicKey) {
                setUserPublicKey(response.data.publicKey);
                navigate('/wallet');
            }
        } catch (err) {
            if (err.response && err.response.status === 401) {
                setError('Incorrect password. Please try again.');
            } else {
                console.error('Error logging into wallet:', err);
                setError('An error occurred while logging into the wallet. Please try again later.');
            }
            setTimeout(() => setError(''), 3000); // Clear the error after 3 seconds
        }
    };

    const handleOverlayClick = (e) => {
        if (e.target.className === 'wallet-modal-overlay') {
            onClose();
            setPassword('');
        }
    };

    const handleClose = () => {
        onClose(); 
        setPassword('');
    };

    return (
        <div className="wallet-modal-overlay" onClick={handleOverlayClick}>
            <div className="wallet-modal">
                <img src={logo} alt="Logo" className="wallet-modal-logo" />
                {error && <div className="wallet-modal-error">{error}</div>}
                {/* Existing modal content */}
                {hasWallet ? (
                    <>
                        <input
                            type="password"
                            placeholder="Enter wallet password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                        />
                        <button onClick={handleLoginWallet}>Login to Wallet</button>
                    </>
                ) : (
                    <>
                        <input
                            type="password"
                            placeholder="Enter wallet password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                        />
                        <button onClick={handleCreateWallet}>Create Wallet</button>
                    </>
                )}
                <button onClick={handleClose} className="close-modal">Close</button>
            </div>
        </div>
    );
};

export default WalletModal;
